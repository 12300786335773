import {NgModule} from '@angular/core';
import {HttpClientModule, HttpHeaders} from '@angular/common/http';
// 1
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
//import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { WebSocketLink } from '@apollo/link-ws';
//import { setContext } from '@apollo/link-context';
import { setContext } from "@apollo/client/link/context";

//import {InMemoryCache} from 'apollo-cache-inmemory';
import { getMainDefinition } from '@apollo/client/utilities';
import { RestLink } from 'apollo-link-rest';
import { onError } from '@apollo/link-error';
import {HttpLink} from 'apollo-angular/http';



import { ApolloClient, createHttpLink, from, InMemoryCache, split, ApolloLink} from '@apollo/client/core';

import {Router} from '@angular/router';
import {SubscriptionClient} from 'subscriptions-transport-ws';
import { ToastController } from '@ionic/angular';
import { ToastControllerComponent } from './toast-controller/toast-controller.component';
import { ApiService } from './services/api.service';
import { BrowserModule } from '@angular/platform-browser';
import { API_SUBSCRIPTIONS, API_URL, environment } from '../environments/environment';
import { API_AUTH_ONPREM } from 'src/environments/environment.onprem';

// Staging

//const api = 'https://04vxo3xzof.execute-api.eu-west-1.amazonaws.com/dev';
//const uriSubscriptions = 'wss://brmdgaad29.execute-api.eu-west-1.amazonaws.com/dev';

/*

/* UNCOMMENT IF FOR PRODUCTION */
// Production
//const api = 'https://64oszkt3o4.execute-api.ap-southeast-2.amazonaws.com/prod';
//const uriSubscriptions = 'wss://20ao3niawb.execute-api.ap-southeast-2.amazonaws.com/prod';



//LOCAL API
//const api = 'http://localhost:9999';



var uriAuth = null;

if (environment.isOnPrem) {
    uriAuth = API_AUTH_ONPREM + '/';
} else {
    uriAuth = API_URL + '/';
}

const uriHttp = API_URL + '/graphql';



export function provideApollo(httpLink: HttpLink, router: Router, toastController: ToastControllerComponent, apiService: ApiService) {

    //console.log(localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') : "");
    const basic = setContext((operation, context) => ({
        headers: {
            Accept: '*/*',
        }
    }));
    


    // Get the authentication token from local storage if it exists
    /*
    const auth = setContext((operation, context) => {
        const token = localStorage.getItem('token');
        return {
            headers: {
                authorization: `Bearer ${token}`
            }
        };
    });
    */


    const authRestLink = new ApolloLink((operation, forward) => {
        operation.setContext(async ({headers}) => {
          return {
            headers: {
              ...headers,
              Accept: "application/json",
              'Content-Type': 'application/keyauth.api.v1',
              Authorization: localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') : ""
            }
          };
        });
        return forward(operation);
      });


        


    /**
     * Perform actions before each http request
     */
    
    
    const middlewareLink = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: {
                authorization: localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') : ""
            }
        });
        return forward(operation);
    });
    
      

/*
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set('content-type','application/json');
    headers.set('content-type','application/x-www-form-urlencoded');

   const linkhttp = httpLink.create({uri: uriHttp, withCredentials: false, headers: headers});
    */


   const linkhttp = httpLink.create({uri: uriHttp});


    const mwlink = middlewareLink.concat(linkhttp);

    const wsclient = new SubscriptionClient(API_SUBSCRIPTIONS, {
            lazy: true, // must be true with the patched subscriptions-transport-ws
            reconnect: true,
            connectionParams: () => {
                const token = localStorage.getItem('token');
                return environment.isOnPrem ? 
                    { Authorization: `Bearer ${token}` }
                :   ({
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
            },
            connectionCallback: (error: Error[]) => {
                console.log('CONNECTION CALLBACK', error);
                console.log('CONNECTION CB TOKEN ON LOCAL STORAGE: ', localStorage.getItem('token'));

                if ( localStorage.getItem('token') === '' ) {
                    console.log('closing wsclient connection...');
                    wsclient.client.close();
                }

                if (error) {
                    console.log(error);
                }
            }
            // inactivityTimeout: 1000  //
        },
        null, // WebSocket,
        environment.isOnPrem ? 'graphql-ws' : []
        );

    const mwsub = {
        applyMiddleware(opts, next) {
            // localStorage.getItem('token');
            console.log('Applying middleware __ mwsub __');

            const ctx = opts.getContext();
            console.log(ctx);
            opts.authorization = ctx.authorization;
            next();
        }
    };

    // wsclient.use([mwsub]);


    const wsLink = new WebSocketLink(wsclient);
    // wsLink.subscriptionClient.use([mwsub]);

    // Hack to help fix token refresh on logout
    (window as any).wslink = wsLink;

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    /*
    const link = split(
        // split based on operation type
        ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription';
        },
        wsLink,
        linkhttp,
        // mwlink
    );
    */


    let link = ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) =>
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              )
            );
          }
          if (networkError) console.error(`[Network error]: ${networkError}`, networkError.stack);
        }),
        ApolloLink.split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription';
            },
          wsLink,
          //linkhttp,
          mwlink
        ),
      ]);

    

    // setup your `RestLink` with your endpoint
    const restLink = new RestLink({ uri: uriAuth });
    

    const elink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) => {
                // Here you may display a message to indicate graphql error
                // You may use 'sweetalert', 'ngx-toastr' or any of your preference
                console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            });
        }
        if (networkError) {
            // Here you may display a message to indicate network error
            // console.log(`[Network error]: ${networkError}`);
            console.log('Status', networkError['status']);
            // router.navigateByUrl('devir');
            switch(networkError['status']){
                case 0:
                case 400:
                case 504:
                    toastController.presentToast('Unable to complete request: ' + networkError['statusText']);
                    // apiService.loading = false; try to stop loading
                    break;
                default:
                    router.navigateByUrl('login');
                    break;
            }
        }
    });


    const defaultOptions = {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore'
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
        },
    };



       /*
      return new ApolloClient({
        cache: new InMemoryCache(),
        link: from([(auth as unknown) as ApolloLink, restLink, elink, link, linkhttp])
        // Note: httpLink is terminating so must be last, while retry & error wrap
        // the links to their right. State & context links should happen before (to
        // the left of) restLink.
      });

       */


      const errorLink = onError(({ response, graphQLErrors, networkError, operation }: any) => {
        if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) => {
                // Here you may display a message to indicate graphql error
                // You may use 'sweetalert', 'ngx-toastr' or any of your preference
                console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            });
        }

        console.log('An Error Occurred');
        console.log('response', response);
        console.log('networkError', networkError);
        console.log('operation', operation);
        console.log('graphQLErrors: ', graphQLErrors);
      });


      const client = {
        link: ApolloLink.from([errorLink, basic, restLink, authRestLink, link]),
        cache: new InMemoryCache(),
        defaultOptions
    };

     
    return client;

     /* 

    return {
        link: ApolloLink.from([elink, basic, restLink, auth, link]),
        cache: new InMemoryCache(),
        defaultOptions
    };
    */
}


@NgModule({
    exports: [
        // 2
        HttpClientModule,
        ApolloModule
        //,HttpLinkModule
    ],
    providers: [{
        provide: APOLLO_OPTIONS,
        useFactory: provideApollo,
        deps: [HttpLink, Router, ToastControllerComponent, ApiService],
    }],
})
export class GraphQLModule {}

